/* The following block can be included in a custom.scss */
@import "~bootstrap/scss/bootstrap";
@import "colors";
@import "assets/css/font-awesome";

$X-small: 575px;
$small: 576px;
$small-medium: 650px;
$medium: 912px;
$large: 992px;
$x-large: 1200px;
$xx-large: 1400px;

$medium-and-large: 912px;

.purple-text {
  font-style: normal;
  color: $color-primary;
}

.white-text {
  font-size: normal;
  color: #fff;
}

.bold {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}

.greyed-text {
  color: #a0a0a0;
}

.purple-text-inverted {
  font-style: normal;
  background-color: $color-primary;
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
}

.no-padding {
  padding: 0 !important;
}

.btn {
  border: 1px $color-gray-first solid;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  color: #bfbfbf;
  opacity: 1;
}
.texto-aviso {
  font-weight: bold;
  color: $color-gray-texto-indisponível;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 30px;

  @media (max-width: $medium-and-large) {
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 4px;
  }
}

.titulo {
  color: $color-primary;
  font-weight: bold;
  letter-spacing: 1.5px;
  text-align: center;
}

:root {
  --toastify-color-light: #6034c7; // #fff
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #fff; // 07bc0c
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #6034c7; // fff
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #fff; // 757575
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #ffff; // ffff
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}
