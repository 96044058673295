@import "../../../../colors";

.login-form {
  .form-label {
    padding-left: 10px;
    color: $color-gray-second;
    font-weight: bold;
    font-size: 14px;
  }
  .input-group {
    .form-control {
      background: #f2f2f2;
      border-radius: 18px;
      font-weight: 500;
      color: $color-gray-second;
      font-size: 14px;
    }
  }
}

.entrar-com-label {
  display: block;
  margin-top: 6px;
  font-size: 14px;
  color: $color-gray-second;
  font-weight: 500;
  text-align: center;
}

.rede-social-login {
  display: flex;
  justify-content: center;
}

.btn-entrar-rede-social {
  cursor: pointer;
  background-color: #eaeff3;
  border: 1px #ccd8ed solid;
  border-radius: 2px;
  font-size: 14px;
  padding: 8px 50px;

  img {
    margin-right: 15px;
  }
}

.bottom-links-wrp {
  text-align: center;
  a {
    font-weight: 600;
  }
}
