/** Colors */

$color-primary: #6034c7;
$color-secondary: #3c1597;
$color-tertiary: #0657df;
$color-quaternary: #a082e5;
$color-quinary: #beaaf9;
$color-primary-contrast: #ffffff;
$color-new: #3780e7;

$color-gray-first: #777777;
$color-gray-second: #818181;
$color-gray-third: #f2f2f2;
$color-gray-fourth: #e6e6e6;
$color-gray-fifth: #c0c0c0;

$color-background-icon: #94d9fc;

$color-gray-texto-indisponível: #bdbdbd;
