@import "../../../../index";
@import "../../../../colors";

.adoo-button {
  background-color: $color-primary;
  border: none;
  color: $color-primary-contrast;
  font-size: 0.75rem;
  padding: 5px 10px;
  border-radius: 14px;
  cursor: pointer;
  transition: 0.3s;

  &:disabled {
    opacity: 0.3;
  }

  &:active {
    background-color: $color-quinary;
    transform: scale(1.1);
    z-index: 2;
  }
}

.adoo-btn {
  &--full-width {
    width: 100%;
  }

  &--planos-precos {
    background-color: #3374c8;
    border-radius: 2px;
    height: 70px;
    width: 200px;
  }

  &--nao-assinar {
    background-color: #e6e6e6;
    border-radius: 2px;
    height: 70px;
    width: 200px;
    font-weight: normal !important;
    color: black;
  }

  &--md {
    font-size: 14px;
  }

  &--lg {
    font-size: 16px;
  }

  &--bold {
    font-weight: bold;
  }

  &--color-tertiary {
    background-color: $color-tertiary;
  }

  &--color-gray {
    background-color: $color-gray-fourth;
    border: 1px solid $color-gray-fifth;
    color: #555;
  }

  &--color-new {
    background-color: $color-new;
  }

  &--right {
    float: right;
  }

  &--spaced-md {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &--glowing {
    animation: glowing 2000ms infinite;
  }
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 -20px $color-new;
  }
  40% {
    box-shadow: 0 0 40px $color-new;
  }
  60% {
    box-shadow: 0 0 40px $color-new;
    color: #eee;
  }
  100% {
    box-shadow: 0 0 -20px $color-new;
  }
}
