@import "../../../../colors";
@import "../../../../index";

.modal-login-width {
  @media (min-width: $large) {
    max-width: none !important;
    width: 1000px !important;
  }
}

.login-modal-panels-wrapper {
  margin-top: -20px;
  display: flex;

  .login-modal-left-panel {
    width: 100%;

    @media (min-width: $large) {
      width: 420px;
    }
  }
  .login-modal-right-panel {
    @media (min-width: $large) {
      z-index: 1;
      margin-top: -11px;
      width: 580px;
      background-color: #efefef;
      border-radius: 10px;
    }
  }
}

.login-modal {
  padding: 0px 30px 20px 30px !important;

  @media (max-width: $medium) {
    padding: 14px;
  }
}

.botton-link {
  color: #0d6efd;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: #0a58ca;
  }
}

.modal-header {
  border-bottom: none !important;
  padding-bottom: 0 !important;

  .btn-close {
    border: 1px #999 solid;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5), 0 10px 50px 5px rgba(0, 0, 0, 0.25),
      inset 1px 1px 0 rgba(255, 255, 255, 0.3);
  }
}

.nav-tabs {
  border-bottom: none !important;
  .nav-item {
    .nav-link {
      padding: 0;
      margin-right: 20px;
      color: $color-gray-second;
      font-weight: 600;

      &.active {
        background-color: transparent;
        border-color: transparent;
        border-bottom: 2px $color-tertiary solid;
        color: black;
      }
      &:hover {
        background-color: transparent;
        border-color: transparent;
        border-bottom: 2px $color-tertiary solid;
      }
    }
  }
}
