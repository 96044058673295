.success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 25px;
  margin: 40px auto;
  text-align: center;
}

.btn-voltar {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
